* {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.landing-home {
    min-height: 100vh;
    background: url('/storage/images/assets/landing_background.webp') center center;
    background-size: cover;
    padding: 3rem 0;
}

.color-teal {
    color: #1DB9A1;
}

.color-orange {
    color: #f18425;
}

.btn-border-white {
    border-radius: 50px;
    border: 1.5px solid white;
    color: white;
}

.bg-teal {
    background-color: #1DB9A1;
}

.bg-orange {
    background-color: #f18425;
}

.btn-teal {
    background: #1DB9A1;
    color: white;
    border-radius: 50px;
}

.btn-orange {
    background: #f18425;
    color: white;
    border-radius: 50px;
}

.border-teal {
    border: 2px solid #1DB9A1;
}

.rounded-20 {
    border-radius: 20px;
}

.section-line {
    background-color: #f18425;
    width: 80px;
    height: 3px;
    margin-bottom: 1rem;
}

.img-shadow {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    position: absolute;
    bottom: 0;
}

.navbar {
    padding: 0.5rem 1rem;
    background: white;
}

.dashboard {
    padding: 6rem 0;
}

.text-loyalty {
    font-size: 84px;
    font-weight: bold;
}

//Datatables
.page-item.active .page-link {
    color: #fff;
    background-color: #1DB9A1;
    border-color: #1DB9A1;
}

.page-link {
    color: #1DB9A1;
}

.page-link:hover {
    color: #1DB9A1;
}

.page-link:focus {
    box-shadow: none;
}

.dataTables_length {
    text-align: left !important;
}

.dataTables_filter {
    @include media-breakpoint-down(sm) {
        text-align: left !important;
    }
}

.dataTables_info {
    padding-top: 0 !important;
    text-align: left !important;
}

table.dataTable.dtr-inline.collapsed.table-sm>tbody>tr>td:first-child::before,
table.dataTable.dtr-inline.collapsed.table-sm>tbody>tr>th:first-child::before {
    top: 50% !important;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    @include media-breakpoint-down(sm) {
        justify-content: loadert !important;
    }
}
